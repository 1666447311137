import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import './App.css';
import './assets/scss/default/index.scss'
import Index from './views/Index'
import 'bootstrap/scss/bootstrap.scss'
import 'animate.css/animate.min.css'

function App() {
  return (
    <div className="App">
      <Router>
        <Route path="/" component={Index} />
      </Router>
    </div>
  );
}

export default App;
