import React, {
    useEffect,
    useState
} from 'react'
import _ from 'lodash'
import ScrollReveal from 'scrollreveal'
import '../assets/fa/css/all.css'
import { web } from '../modules/http'

export default () => {
    const [model, setModel] = useState({...initalModel()})
    const [formControl, setFormControl] = useState({
        onSubmit: false,
        isLoading: false,
        onSuccess: false
    })
    const [formErrors, setFormErrors] = useState({})
    useEffect(() => {
        window.addEventListener('scroll', toScroll)
        /*===== MENU SHOW =====*/
        const showMenu = (toggleId, navId) => {
            const toggle = document.getElementById(toggleId),
                nav = document.getElementById(navId)

            if (toggle && nav) {
                toggle.addEventListener('click', () => {
                    nav.classList.toggle('show')
                })
            }
        }
        showMenu('nav-toggle', 'nav-menu')

        /*===== ACTIVE AND REMOVE MENU =====*/
        const navLink = document.querySelectorAll('.nav__link');

        function linkAction() {
            /*Active link*/
            navLink.forEach(n => n.classList.remove('active'));
            this.classList.add('active');

            /*Remove menu mobile*/
            const navMenu = document.getElementById('nav-menu')
            navMenu.classList.remove('show')
        }
        navLink.forEach(n => n.addEventListener('click', linkAction));

        /*===== SCROLL REVEAL ANIMATION =====*/
        const sr = ScrollReveal({
            origin: 'top',
            distance: '150px',
            duration: 2000,
            reset: true
        });

        /*SCROLL HOME*/
        sr.reveal('.home__title', {});
        sr.reveal('.button', { delay: 200 });
        sr.reveal('.home__img', { delay: 400 });
        sr.reveal('.home__social-icon', { interval: 200 });

        /*SCROLL ABOUT*/
        sr.reveal('.about__img', {});
        sr.reveal('.about__subtitle', { delay: 400 });
        sr.reveal('.about__text', { delay: 400 });

        /*SCROLL SKILLS*/
        sr.reveal('.skills__subtitle', {});
        sr.reveal('.skills__text', {});
        sr.reveal('.skills__data', { interval: 200 });
        sr.reveal('.skills__img', { delay: 600 });
        sr.reveal('.dev-icons', {})

        /*SCROLL WORK*/
        sr.reveal('.work__img', { interval: 200 });

        /*SCROLL CONTACT*/
        sr.reveal('.contact__input', { interval: 200 });
    }, [])

    useEffect(() => {
        if (formControl.onSubmit) {
            if (formErrors && Object.keys(formErrors).length) {
                setFormControl({...formControl, isLoading: false, onSubmit: false, onSuccess: false})
                return
            }
            setFormControl({...formControl, isLoading: true})
            web.post('email/', model)
                .then(r => {
                    setFormControl({...formControl, isLoading: false, onSubmit: false, onSuccess: false})
                    if (r.data) {
                        setModel({...initalModel()})
                        setFormControl({...formControl, onSubmit: false, onSuccess: true})
                        setTimeout(() => {
                            setFormControl({...formControl, onSubmit: false, onSuccess: false})
                        }, 5000);
                    }
                })
            }
    }, [formControl.onSubmit])

    const toScroll = () => {
        const aboutElemt = document.getElementById('about')
        const headerMenu = document.getElementsByClassName('l-header')[0]
        const homeClass = document.getElementById('home')

        if (aboutElemt && aboutElemt.offsetTop - 150 < document.scrollingElement.scrollTop && !homeClass.classList.contains('is_scrolling')) {
            headerMenu.classList.remove('hide');
            // setTimeout(() => {
            //     homeClass.classList.add('hide')
            // }, 1000);
        } else {
            headerMenu && headerMenu.classList.add('hide')
        }
    }

    const backtoTop = () => {
        const rootElement = document.documentElement
        document.getElementsByClassName('l-header')[0].classList.add('hide')
        const homeClass = document.getElementById('home')
        homeClass.classList.remove('hide')
        homeClass.classList.add('is_scrolling')

        rootElement.scrollTo({
            top: 0,
            behavior: "smooth"
        })

        setTimeout(() => {
            homeClass.classList.remove('is_scrolling')
        }, 2000);
    }
    
    const handleModel = (e) => {
        console.log('eee', e.target.value)
        setModel({ ...model, [e.target.name]: e.target.value })
    }

    const validate = () => {
        let errors = {}
        if (model.wname === '') {
            errors.wname = 'Name is required.'
        }
        if (model.email === '') {
            errors.email = 'Email is required.'
        }
        if (model.message === '') {
            errors.message = 'Your Inquiry is important, please fill it up.'
        }
        if (model.email !== '' && ! /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(model.email)) {
            errors.email = 'The email you entered is invalid.'
        }

        return errors
    }

    const send = () => {
        // web.post('/api/v1/post/form/email', model)
        setFormErrors(validate())
        setFormControl({...formControl, onSubmit: true})
    }


    return (
        <div>
            {/*===== HEADER =====*/}
            <header className="l-header animate__animated animate__fadeInDown hide">
                <nav className="nav bd-grid">
                    <div>
                        <a href="#home" onClick={backtoTop} className="nav__logo"><img width="40" src={require('../assets/images/fordbedia-logo.png')} /></a>
                    </div>
                    <div className="nav__menu" id="nav-menu">
                        <ul className="nav__list">
                            <li className="nav__item"><a href="#about" className="nav__link">About</a></li>
                            <li className="nav__item"><a href="#skills" className="nav__link">Skills</a></li>
                            {/* <li className="nav__item"><a href="#work" className="nav__link">Work</a></li> */}
                            <li className="nav__item"><a href="#contact" className="nav__link">Contact</a></li>
                        </ul>
                    </div>
                    <div className="nav__toggle" id="nav-toggle">
                        <i className="bx bx-menu" />
                    </div>
                </nav>
            </header>
            <main className="l-main">
                {/*===== HOME =====*/}
                <section className="home bd-grid" id="home">
                    <div className="intro-wrapper">
                        <div class="nav-wrapper">
                            <a href="#">
                                <div className="d-flex">
                                    <div class="dots-wrapper">
                                        <div id="dot-1" class="browser-dot"></div>
                                        <div id="dot-2" class="browser-dot"></div>
                                        <div id="dot-3" class="browser-dot"></div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="home__data px-4">
                                <h1 className="home__title">Hi,<br />I'am <span className="home__title-color">Ed</span><br /> a Full-Stack <br />Software Engineer</h1>
                                <a href="#about" className="button">About</a>
                            </div>
                            <div className="home__img px-4 py-5">
                                <img className="img-fluid" src={require('../assets/images/FordBedia.png')} alt="" />
                            </div>
                        </div>
                        <div className="home__social">
                            <a href="https://www.linkedin.com/in/edford-patrick-bedia-4a33456a" className="home__social-icon"><i className="bx bxl-linkedin" /></a>
                            <a href="https://www.instagram.com/fordbedia/" className="home__social-icon"><i className="bx bxl-instagram" /></a>
                            <a href="https://github.com/fordbedia/" className="home__social-icon"><i className="bx bxl-github" /></a>
                            <a href="mailto:inquire@fordbedia.com" className="home__social-icon"><i class="fas fa-at"></i></a>
                        </div>
                    </div>
                </section>
                {/*===== ABOUT =====*/}
                <section className="about section " id="about">
                    <h2 className="section-title">About</h2>
                    <div className="about__container bd-grid">
                        <div className="about__img">
                            <img src={require('../assets/images/IMG_2033 2.JPG')} alt="" />
                        </div>
                        <div>
                            {/* <h2 className="about__subtitle">I'm Ed</h2> */}
                            <p className="about__text">I am experienced in leveraging agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition.</p>
                        </div>
                    </div>
                </section>
                {/*===== SKILLS =====*/}
                <section className="skills section" id="skills">
                    <h2 className="section-title">Skills</h2>
                    <div className="skills__container bd-grid">
                        <div>
                            <h2 className="skills__subtitle">Professional Skills</h2>
                            <p className="skills__text">Languages, Libraries/Frameworks and Tools used</p>
                            <ul class="list-inline dev-icons mb-5">
                                <li class="list-inline-item"><i class="fab fa-html5"></i></li>
                                <li class="list-inline-item"><i class="fab fa-css3-alt"></i></li>
                                <li class="list-inline-item"><i class="fab fa-sass"></i></li>
                                <li class="list-inline-item"><i class="fab fa-less"></i></li>
                                <li class="list-inline-item"><i class="fab fa-gulp"></i></li>
                                <li class="list-inline-item"><i class="fab fa-grunt"></i></li>
                                <li class="list-inline-item"><i class="fab fa-npm"></i></li>
                                <li class="list-inline-item"><i class="fab fa-yarn"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-babel-plain"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-webpack-plain-wordmark"></i></li>
                                <li class="list-inline-item"><i class="fab fa-js-square"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-jquery-plain-wordmark"></i></li>
                                <li class="list-inline-item"><i class="fab fa-node"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-mocha-plain"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-express-original-wordmark"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-vuejs-plain-wordmark"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-react-original-wordmark"></i></li>
                                <li class="list-inline-item"><i class="fab fa-angular"></i></li>
                                <li class="list-inline-item"><i class="fab fa-php"></i></li>
                                <li class="list-inline-item"><i class="fab fa-laravel"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-codeigniter-plain-wordmark"></i></li>
                                <li class="list-inline-item"><i class="fab fa-wordpress"></i></li>
                                <li class="list-inline-item"><i class="fab fa-python"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-django-line-wordmark"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-git-plain"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-vim-plain"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-redis-plain-wordmark"></i></li>
                                <li class="list-inline-item"><i class="devicon-github-plain-wordmark colored"></i></li>
                            </ul>
                            <p className="skills__text">Database used</p>
                            <ul class="list-inline dev-icons mb-5">
                                <li class="list-inline-item"><i class="skill-fa devicon-mysql-plain-wordmark"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-postgresql-plain-wordmark"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-mongodb-plain-wordmark"></i></li>
                            </ul>
                            <p className="skills__text">Cloud used</p>
                            <ul class="list-inline dev-icons mb-5">
                                <li class="list-inline-item"><i class="skill-fa devicon-amazonwebservices-plain-wordmark"></i></li>
                                <li class="list-inline-item"><i class="fab fa-digital-ocean"></i></li>
                                <li class="list-inline-item"><i class="skill-fa devicon-heroku-original-wordmark"></i></li>
                            </ul>
                            {/* <div className="skills__data">
                                <div className="skills__names">
                                    <i className="bx bxl-html5 skills__icon" />
                                    <span className="skills__name">HTML5</span>
                                </div>
                                <div className="skills__bar skills__html">
                                </div>
                                <div>
                                    <span className="skills__percentage">95%</span>
                                </div>
                            </div>
                            <div className="skills__data">
                                <div className="skills__names">
                                    <i className="bx bxl-css3 skills__icon" />
                                    <span className="skills__name">CSS3</span>
                                </div>
                                <div className="skills__bar skills__css">
                                </div>
                                <div>
                                    <span className="skills__percentage">85%</span>
                                </div>
                            </div>
                            <div className="skills__data">
                                <div className="skills__names">
                                    <i className="bx bxl-javascript skills__icon" />
                                    <span className="skills__name">JAVASCRIPT</span>
                                </div>
                                <div className="skills__bar skills__js">
                                </div>
                                <div>
                                    <span className="skills__percentage">65%</span>
                                </div>
                            </div>
                            <div className="skills__data">
                                <div className="skills__names">
                                    <i className="bx bxs-paint skills__icon" />
                                    <span className="skills__name">UX/UI</span>
                                </div>
                                <div className="skills__bar skills__ux">
                                </div>
                                <div>
                                    <span className="skills__percentage">85%</span>
                                </div>
                            </div> */}
                        </div>
                        <div>
                            <img src={require('../assets/images/c-img.jpg')} alt="" className="skills__img" />
                        </div>
                    </div>
                </section>
                {/*===== WORK =====*/}
                {/* <section className="work section" id="work">
                    <h2 className="section-title">Work</h2>
                    <div className="work__container bd-grid">
                        <div className="work__img">
                            <img src="assets/img/work1.jpg" alt="" />
                        </div>
                        <div className="work__img">
                            <img src="assets/img/work2.jpg" alt="" />
                        </div>
                        <div className="work__img">
                            <img src="assets/img/work3.jpg" alt="" />
                        </div>
                        <div className="work__img">
                            <img src="assets/img/work4.jpg" alt="" />
                        </div>
                        <div className="work__img">
                            <img src="assets/img/work5.jpg" alt="" />
                        </div>
                        <div className="work__img">
                            <img src="assets/img/work6.jpg" alt="" />
                        </div>
                    </div>
                </section> */}
                {/*===== CONTACT =====*/}
                <section className="contact section" id="contact">
                    <h2 className="section-title">Contact</h2>
                    <div className="contact__container bd-grid">
                        <form action className="contact__form">
                            <input name="wname" onChange={(e) => handleModel(e)} type="text" placeholder="Name" className="contact__input" value={model.wname} />
                            <span className="errors">{formErrors && Object.keys(formErrors).length > 0 && typeof formErrors['wname'] !== 'undefined' && formErrors['wname']}</span>
                            <div class="spacer"></div>
                            <input name="email" onChange={(e) => handleModel(e)} type="mail" placeholder="Email" className="contact__input" value={model.email} />
                            <span className="errors">{formErrors && Object.keys(formErrors).length > 0 && typeof formErrors['email'] !== 'undefined' && formErrors['email']}</span>
                            <div class="spacer"></div>
                            <textarea placeholder="Your Inquiry" name="message" onChange={(e) => handleModel(e)} id cols={0} rows={10} className="contact__input" value={model.message} />
                            <span className="errors">{formErrors && Object.keys(formErrors).length > 0 && typeof formErrors['message'] !== 'undefined' && formErrors['message']}</span>
                            <div class="spacer"></div>
                            <button onClick={send} type="button" className="contact__button button">{formControl.isLoading ? 'Sending...' : 'Send'}</button>
                            {formControl && Object.keys(formControl).length > 0 && formControl.onSuccess && 
                            (<div className="on-success">
                                <p>Thank you. I will soon contact you.</p>
                            </div>)}
                        </form>
                    </div>
                </section>
            </main>
            {/*===== FOOTER =====*/}
            <footer className="footer">
                {/* <p className="footer__title">Ed Bedia</p> */}
                <div className="d-flex justify-content-center pb-3">
                    <img style={{ width: 100, height: 70 }} width="100" height="100" className="img-fluid" src={require('../assets/images/logo-icon.png')} />
                </div>
                <div className="footer__social">
                    <a href="https://github.com/fordbedia/" className="footer__icon"><i className="bx bxl-github" /></a>
                    <a href="https://www.instagram.com/fordbedia/" className="footer__icon"><i className="bx bxl-instagram" /></a>
                    <a href="https://www.linkedin.com/in/edford-patrick-bedia-4a33456a" className="footer__icon"><i className="bx bxl-linkedin" /></a>
                    <a href="mailto:inquire@fordbedia.com" className="footer__icon"><i class="fas fa-at"></i></a>
                </div>
                <p>Copyright ©  2020 - {new Date().getFullYear()}. <a href="https://fordbedia.com">fordbedia.com</a> All rights reserved. </p>
            </footer>
        </div>
    )
}

const initalModel = () => ({
    wname: '',
    email: '',
    message: ''
})